import * as React from 'react'

import StyledEbestWebViewLayout from './styled'

type Props = {
  children: JSX.Element | JSX.Element[]
}

export default function EbestWebViewLayout({ children }: Props) {
  return (
    <StyledEbestWebViewLayout>
      <main>{children}</main>
    </StyledEbestWebViewLayout>
  )
}
