import styled from 'styled-components'

const StyledEbestWebViewLayout = styled.div`
  background-color: var(--color-ebest-grayscale-gray50);

  > main {
    margin: auto;
    width: 40rem;
    min-height: 100vh;
    background-color: var(--color-ebest-primary-white);

    @media (max-width: 767px) {
      width: 100%;

      min-height: -moz-available;
      min-height: -webkit-fill-available;
      min-height: fill-available;
    }
  }

  // 텍스트 드래그 금지
  -ms-user-select: none;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
`

export default StyledEbestWebViewLayout
