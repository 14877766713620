import styled from 'styled-components'

const StyledEbestWebViewModalHeader = styled.div`
  height: var(--spacing-legacy-60);

  .title {
    font-size: var(--font-size-16);
    line-height: var(--spacing-legacy-19);
    color: var(--color-ebest-grayscale-gray900);
    font-weight: 700;

    margin-left: var(--spacing-legacy-4);
  }

  > button {
    height: 100%;
    width: calc(var(--spacing-legacy-28) + var(--spacing-legacy-20));
  }

  .go-back-image-wrap {
    width: var(--spacing-legacy-28);
    height: var(--spacing-legacy-28);
  }
`

export default StyledEbestWebViewModalHeader
