import React from 'react'

import StyledEbestWebViewModalHeader from '@/components/pages/ebest/ebest-components/ebest-webview-modal-header/styled'
import Image from '@/components/common/image'

const EbestWebViewModalHeader = ({ onBack, title }: { title: string; onBack: () => void }) => {
  return (
    <StyledEbestWebViewModalHeader className="--flex-x-center">
      <button type="button" className="--flex-y-end --flex-x-center" onClick={onBack}>
        <div className="go-back-image-wrap">
          <Image
            src="/images/icons/arrow-left-black.svg"
            alt="back arrow button"
            layout="responsive"
            width={28}
            height={28}
          />
        </div>
      </button>
      <h2 className="title">{title ?? ''}</h2>
    </StyledEbestWebViewModalHeader>
  )
}

export default EbestWebViewModalHeader
